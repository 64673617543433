import React from 'react';
import Icon1 from '../../images/svg-6.svg';
import Icon2 from '../../images/svg-3.svg';
import Icon3 from '../../images/svg-5.svg';
import {
  ServicesContainer,
  ServicesH1,
  ServicesWrapper,
  ServicesCard,
  ServicesIcon,
  ServicesH2,
  ServicesP,
} from './ServicesElements';

const Services = () => {
  return (
    <ServicesContainer id='services'>
      <ServicesH1>Services</ServicesH1>
      <ServicesWrapper>
        <ServicesCard>
          <ServicesIcon src={Icon1} />
          <ServicesH2>Web Services</ServicesH2>
          <ServicesP>
            We provide professional and quality web design solutions. All
            projects are thoroughly tested by our quality assurance team after
            development.
          </ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Icon2} />
          <ServicesH2>Mobile App Development</ServicesH2>
          <ServicesP>
            Our team of expertise has helped many clients with a variety of
            different apps from complex social media to simple informative apps.
          </ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Icon3} />
          <ServicesH2>Site Maintenance</ServicesH2>
          <ServicesP>
            Already have a website that needs maintenance or an upgrade? No
            problem! Our development team is here to take your site to the next
            level.
          </ServicesP>
        </ServicesCard>
      </ServicesWrapper>
    </ServicesContainer>
  );
};

export default Services;
