import React, { useEffect, useState } from 'react';
import { Button } from '../ButtonElements';

import Video from '../../videos/video.mp4';
import {
  HeroContainer,
  HeroBg,
  VideoBg,
  HeroContent,
  HeroH1,
  HeroP,
  HeroBtnWrapper,
  ArrowForward,
  ArrowRight,
} from './HeroElements';

function HeroSection() {
  const [hover, setHover] = useState(false);
  const [webUrl, setWebUrl] = useState('');

  const onHover = () => {
    setHover(!hover);
  };
  useEffect(() => {
    // Get the full current URL
    const fullUrl = window.location.href;

    // Find the position of 'l=' in the URL
    const index = fullUrl.indexOf('l=');
    if (index !== -1) {
      // Extract everything after 'l='
      const link = fullUrl.substring(index + 2);
      console.log(link);
      setWebUrl(link);
    } else {
      // Set a default URL if 'l=' is not found
      setWebUrl('#services');
    }
  }, []);
  function handleLearnMoreClick() {
    // Open the standard URL in the current tab
    window.location.href = webUrl;
  };
  return (
    <HeroContainer id='home'>
      <HeroBg>
        <VideoBg playsInline autoPlay loop muted src={Video} type='video/mp4' />
      </HeroBg>
      <HeroContent>
        <HeroH1>Welcome..</HeroH1>
        <HeroP>
          Impeccable tech development services at a reasonable budget. Optimized
          solutions to help your business.
        </HeroP>
        <HeroBtnWrapper>
          <Button
          // onClick={handleLearnMoreClick}
            to='services'
            smooth={true}
            duration={500}
            spy={true}
            exact='true'
            offset={-80}
            primary='true'
            dark='true'
            onMouseEnter={onHover}
            onMouseLeave={onHover}
          >
            Learn More {hover ? <ArrowForward /> : <ArrowRight />}
          </Button>
        </HeroBtnWrapper>
      </HeroContent>
    </HeroContainer>
  );
}

export default HeroSection;
